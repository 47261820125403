/*
/// This is the code that works well with trix
/// from base camp's code samples: https://trix-editor.org/js/attachments.js

(function() {
  var HOST = "https://d13txem1unpe48.cloudfront.net/"

  addEventListener("trix-attachment-add", function(event) {
    if (event.attachment.file) {
      uploadFileAttachment(event.attachment)
    }
  })

  function uploadFileAttachment(attachment) {
    uploadFile(attachment.file, setProgress, setAttributes)

    function setProgress(progress) {
      attachment.setUploadProgress(progress)
    }

    function setAttributes(attributes) {
      attachment.setAttributes(attributes)
    }
  }

  function uploadFile(file, progressCallback, successCallback) {
    var key = createStorageKey(file)
    var formData = createFormData(key, file)
    var xhr = new XMLHttpRequest()

    xhr.open("POST", HOST, true)

    xhr.upload.addEventListener("progress", function(event) {
      var progress = event.loaded / event.total * 100
      progressCallback(progress)
    })

    xhr.addEventListener("load", function(event) {
      if (xhr.status == 204) {
        var attributes = {
          url: HOST + key,
          href: HOST + key + "?content-disposition=attachment"
        }
        successCallback(attributes)
      }
    })

    xhr.send(formData)
  }

  function createStorageKey(file) {
    var date = new Date()
    var day = date.toISOString().slice(0,10)
    var name = date.getTime() + "-" + file.name
    return [ "tmp", day, name ].join("/")
  }

  function createFormData(key, file) {
    var data = new FormData()
    data.append("key", key)
    data.append("Content-Type", file.type)
    data.append("file", file)
    return data
  }
})();



*/



document.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
});

/*
// Listen for the Trix attachment event to trigger upload

document.addEventListener("trix-attachment-add", function(event) {  
  var attachment = event.attachment;
  if (attachment.file) {
    return uploadAttachment(attachment);
  }
});



function uploadAttachment(attachment) {

  // Create our form data to submit
  var file = attachment.file;
  var form = new FormData;
  form.append("Content-Type", file.type);
  form.append("document[file]", file);

  // Create our XHR request
  var xhr = new XMLHttpRequest;
  xhr.open("POST", "/documents.json", true);

  var csrfToken = $('meta[name="csrf-token"]').attr('content');
  xhr.setRequestHeader("X-CSRF-Token", csrfToken);  

  // Report file uploads back to Trix
  xhr.upload.onprogress = function(event) {
    var progress = event.loaded / event.total * 100;
    attachment.setUploadProgress(progress);
  }

  // Tell Trix what url and href to use on successful upload
  xhr.onload = function() {
    if (xhr.status === 201) {
      var data = JSON.parse(xhr.responseText);      
      return attachment.setAttributes({               
        url: data.file_url,
        href: data.file_url
      })
    }
  }

  return xhr.send(form);
}


*/