import { Controller } from "@hotwired/stimulus"
import { Elm } from '../DocumentSearch.elm'

import {Sha1Module} from "../sha1Js/sha1"

export default class extends Controller {

  static targets = ["sha1App", "elmApp"]

  connect(){
    this.setUpDocumentSearchAndJoblesApp()
  }

    setUpDocumentSearchAndJoblesApp(){
      if (this.hasElmAppTarget) {        
          let node = this.elmAppTarget          
          let user = JSON.parse(node.getAttribute('data-user'))          
          let project = JSON.parse(node.getAttribute('data-project'))       

          var elmApp = Elm.DocumentSearch.init({node: node, flags: {project: project, user: user}  })          
          
          let joblesApp = Sha1Module.init({
              node: this.sha1AppTarget,
              callback: passShaToElm
           })

           function passShaToElm(json_payload){ 
              elmApp.ports.fileReceiver.send(JSON.parse(json_payload))
           }
          
      }
    }     
}