import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash.debounce'
import {hyper, wire, bind, Component} from 'hyperhtml/esm';

import * as EmailValidator from 'email-validator';

import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'input', 'results', 'emailDisplay', 'hiddenEmailSelect', "emailDisplayButton"]

 static values = {
    url: String, 
    userId: Number,
    emailValidationUrl: String
  }

  connect() {    
    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute('email-input', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.mouseDown = false

    this.onInputChange = debounce(this.onInputChange.bind(this), 300)
    this.onResultsClick = this.onResultsClick.bind(this)
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onKeydown = this.onKeydown.bind(this)

    this.inputTarget.addEventListener('keydown', this.onKeydown)
    this.inputTarget.addEventListener('blur', this.onInputBlur)
    this.inputTarget.addEventListener('input', this.onInputChange)
    this.resultsTarget.addEventListener('mousedown', this.onResultsMouseDown)
    this.resultsTarget.addEventListener('click', this.onResultsClick)

    this.inputTarget.focus()
  }

  disconnect() {
    this.inputTarget.removeEventListener('keydown', this.onKeydown)
    this.inputTarget.removeEventListener('focus', this.onInputFocus)
    this.inputTarget.removeEventListener('blur', this.onInputBlur)
    this.inputTarget.removeEventListener('input', this.onInputChange)
    this.resultsTarget.removeEventListener('mousedown', this.onResultsMouseDown)
    this.resultsTarget.removeEventListener('click', this.onResultsClick)
  }

  sibling(next) {
    const options = Array.from(this.resultsTarget.querySelectorAll('[role="option"]'))
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  setCurrent(event){    
    this.select(event.target)
  }

  select(target) {
    for (const el of this.resultsTarget.querySelectorAll('[aria-selected="true"]')) {
      el.removeAttribute('aria-selected')
      el.classList.remove('active')
    }
    target.setAttribute('aria-selected', 'true')
    target.classList.add('active')
    this.inputTarget.setAttribute('aria-activedescendant', target.id)
  }

  onKeydown(event) {
    switch (event.key) {
      case 'Escape':
        if (!this.resultsTarget.hidden) {
          this.hideAndRemoveOptions()
          event.stopPropagation()
          event.preventDefault()
        }
        break
      case 'ArrowDown':
        {          
          const item = this.sibling(true)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case 'ArrowUp':
        {
          const item = this.sibling(false)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case 'Tab':
        {
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]')          
          if (selected) {
            this.commit(selected)
          }

          this.validateEmailAndAddToListIfRequired()
        }
        break
      case 'Enter':
        {
          event.preventDefault()
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected)            
          }
          else{                 
            this.validateEmailAndAddToListIfRequired()
          }
        }
        break
    }
  }

  validateEmailAndAddToListIfRequired(){
            let parsedEmail = this.inputTarget.value.trim().toLowerCase();

            if (EmailValidator.validate(parsedEmail)) {              

                let formData = new FormData();
                formData.append('email', parsedEmail);
                
                 post(this.data.get("emailValidationUrl"), {
                  body: formData,                  
                  credentials: "same-origin"
                })
                .then(response => {                  
                  return response.json
                })
                .then(data => {                                  
                               if(this.doesUserAlreadyExist(parsedEmail)){
                                 // do nothing because the user is already there
                               }
                               else
                               {
                                  if (data == true){
                                    this.addUserEmailToList(parsedEmail)  
                                    this.addUserButtonTemplate(parsedEmail, parsedEmail)                                
                                  }
                               }              
                               this.inputTarget.value = "";    
                               this.inputTarget.classList.remove("alert","alert-danger");                          
                })  
            }
            else{
              this.inputTarget.value = "";    
            }              
  }

  onInputBlur() {
    if (this.mouseDown) return
    this.resultsTarget.hidden = true
  this.validateEmailAndAddToListIfRequired()
  }

  commit(selected) {
    if (selected.getAttribute('aria-disabled') === 'true') return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.resultsTarget.hidden = true
      return
    }

    const textValue = selected.textContent.trim()
    const value = selected.getAttribute('data-email-input-value') || textValue           

    if (this.doesUserAlreadyExist(value)) {
      // do nothing, because the user is already there. 
    }
    else
    {
      this.addUserEmailToList(value)  // added
      this.addUserButtonTemplate(value, textValue)    //added   
    }

    this.inputTarget.value = ""

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(new CustomEvent('email-input.change', {
      bubbles: true,
      detail: { value: value, textValue: textValue }
    }))
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest('[role="option"]')
    if (selected) this.commit(selected)
  }

  onResultsMouseDown() {
    this.mouseDown = true
    this.resultsTarget.addEventListener('mouseup', () => (this.mouseDown = false), {once: true})
  }

  onInputChange() {
    this.element.removeAttribute('value')
    this.fetchResults()
  }

  identifyOptions() {
    let id = 0
    for (const el of this.resultsTarget.querySelectorAll('[role="option"]:not([id])')) {
      el.id = `${this.resultsTarget.id}-option-${id++}`
    }
  }

  clearEntry(){
    if (this.inputTarget.value) {
      this.inputTarget.value = ""
    }    
  }

  hideAndRemoveOptions() {
    this.resultsTarget.hidden = true
    this.resultsTarget.innerHTML = null
  }

  fetchResults() {
    const query = this.inputTarget.value.trim()
    if (!query || query.length < this.minLength) {
      this.hideAndRemoveOptions()
      return
    }

    if (!this.src) return

    const url = new URL(this.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('q', query)
    url.search = params.toString()

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString())
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.identifyOptions()
        const hasResults = !!this.resultsTarget.querySelector('[role="option"]')
        this.resultsTarget.hidden = !hasResults
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  open() {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute('aria-expanded', 'true')
    this.element.dispatchEvent(new CustomEvent('toggle', {detail: {input: this.input, results: this.results}}))
  }  

  close() {
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute('aria-activedescendant')
    this.element.setAttribute('aria-expanded', 'false')
    this.element.dispatchEvent(new CustomEvent('toggle', {detail: {input: this.input, results: this.results}}))
  }

  get src() {
    return this.data.get("url")
  }

  get minLength() {
    const minLength = this.data.get("min-length")
    if ( !minLength ) {
      return 0
    }
    return parseInt(minLength, 10)
  }

  removeMail(event){
    event.stopPropagation()
    event.preventDefault()             
    
    let userId = event.currentTarget.dataset.userId      
    
    // remove all references to the email in the hidden element
    if (this.hiddenEmailSelectTarget.length > 0) {
      for (var i = this.hiddenEmailSelectTarget.length - 1; i >= 0; i--) {
        if (this.hiddenEmailSelectTarget[i].value === userId)
        {
          this.hiddenEmailSelectTarget.removeChild(this.hiddenEmailSelectTarget.options[i])
        }        
      }
    }      
    
    // hide the button
    if (this.hasEmailDisplayButtonTarget) {
      for (var i = this.emailDisplayButtonTargets.length - 1; i >= 0; i--) {
        if (this.emailDisplayButtonTargets[i].dataset.userId === userId)
        {
          this.emailDisplayButtonTargets[i].remove()
        }        
      }
    }    
  }

  doesUserAlreadyExist(userId){
      for (let option of this.hiddenEmailSelectTarget.options) 
      {       

        if (option.value.toUpperCase() === userId.toUpperCase()) 
        { 
          return true
        } 
      }

      return false      
  }

  addUserButtonTemplate(userEmail, userName){
    this.emailDisplayTarget.insertAdjacentHTML("afterbegin",  this.getUserButtonTemplate(userEmail, userName).outerHTML     )       
  }

  getUserButtonTemplate(userEmail, userName){
    return hyper.wire(this.emailDisplayTarget)`  
    <div class="d-inline-flex" data-email-input-target="emailDisplayButton" data-action="click->email-input#removeMail" data-user-id="${userEmail}">
      <p class="d-inline-flex border border-primary rounded-pill ps-3 pe-3">
        ${userName}
        <button type="button" class="btn-close d-flex" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </p>
    </div>`
  }


  addUserEmailToList(userEmail){
    var opt = document.createElement('option');
    opt.appendChild( document.createTextNode(userEmail));    
    opt.value = userEmail
    opt.setAttribute("selected", "selected")
    this.hiddenEmailSelectTarget.appendChild(opt)  
  }

 getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}


}