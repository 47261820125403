import { Controller } from "@hotwired/stimulus";

import { singleFileUpload, multipleFileUpload } from '../fileUpload'

export default class extends Controller {
  
  static targets = [ "imagePreview", "uploadResult" ]

  connect(){  	  

      this.element.querySelectorAll('input[type=file]').forEach(fileInput => {
      if (fileInput.multiple) {
        multipleFileUpload(fileInput)
      } else {
        singleFileUpload(fileInput, this.imagePreviewTarget, this.uploadResultTarget)
      }
    })    
  }
}