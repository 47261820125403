import { Controller } from "@hotwired/stimulus"

import { Elm } from '../Decider.elm'

// import { Elm } from '../Decider'
// import {Elm} from '../Counter'

import {Sha1Module} from "../sha1Js/sha1.js"

export default class extends Controller {

  static targets = [ "sha1App", "elmApp", "downloadApp"]

  connect(){   
    console.log("decider app")   
    this.setUpDownloadAppAndJoblesApp()    
  }

    setUpDownloadAppAndJoblesApp(){
      if (this.hasDownloadAppTarget) {        
          let node = this.downloadAppTarget
          let documents = JSON.parse(node.getAttribute('data-documents'))
          let conversation = JSON.parse(node.getAttribute('data-conversation'))
          let message = JSON.parse(node.getAttribute('data-message'))      

          var elmApp = Elm.Decider.init({node: node, flags: {documents: documents, message: message}  })          
          
          if (documents.length > 0) {
              let joblesApp = Sha1Module.init({
                node: this.sha1AppTarget,
                callback: passShaToElm
              })

              function passShaToElm(json_payload){ 
                elmApp.ports.fileReceiver.send(JSON.parse(json_payload))
              }
          }
      }
    }    
}