// Entry point for the build script in your package.json

// remove ".packs/application" and dump it in here.

import "./controllers"


import Trix from "trix";

// window.Trix = Trix;
// require("@rails/actiontext")

import "./trix/trix_attachments.js";
import "./trix/trix_toolbar.js";

import LocalTime from "local-time"
LocalTime.start();

let browserUpdate = require('browser-update');
browserUpdate({
    required: {
        e:-2,
        i:11,
        f:-1,
        o:-3,
        s:10.1,
        c:"64.0.3282.16817",
        samsung: 7.0,
        vivaldi: 1.2
    },
    insecure:true
})

import("csrf-xhr");

import { Turbo } from "@hotwired/turbo-rails";

import * as bootstrap from "bootstrap";






