import {
  createAvatar
} from '@dicebear/core';

import * as initials from '@dicebear/initials';

import { Controller } from "@hotwired/stimulus"

/*
<div data-controller="avatar" 
     data-avatar-name-value="<%= current_user&.name %>" 
     class="h-10 w-10 rounded-full">  
</div>
*/

export default class extends Controller { 

  static values = {
    name: String    
  }

  connect() {
    let svg = createAvatar(initials, {
        seed: this.nameValue,
        radius: 50
      });       

    this.element.innerHTML = svg;
  }
}